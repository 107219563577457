import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.css']
})
export class NosotrosComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Nosotros');
	  this.meta.updateTag({ name: 'description', content: 'Somos una empresa que se dedica a brindar soluciones innovadoras a las necesidades actuales de las empresas.' });
    // tslint:disable-next-line:only-arrow-functions
    $(window).scroll(function() {
      var fromTop = $(window).scrollTop();
      $("#form01").css('margin', (208 - (fromTop * 0.2 )) + 'px 0px 0px 0px');
      $("#form02").css('margin', (712 + (fromTop * 0.2 )) + 'px 0px 0px 0px');
      $("#form03").css('margin', (782 + (fromTop * 0.1 )) + 'px 0px 0px 0px');
      $("#form04").css('margin', (882 - (fromTop * 0.2 ) + 50) + 'px 0px 0px 0px');
      $("#form05").css('margin', (1282 + (fromTop * 0.5 ) + 80) + 'px 0px 0px 0px');
      $("#form06").css('margin', (1382 - (fromTop * 0.3 ) + 90) + 'px 0px 0px 0px');
      $("#form07").css('margin', (1882 - (fromTop * 0.1 ) + 120) + 'px 0px 0px 0px');
      $("#form08").css('margin', (1782 - (fromTop * 0.4 ) + 160) + 'px 0px 0px 0px');
    });
  }

}
