import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  images = [1,2,3,4,5,6,7,8].map((n) => `http://localhost:4200/assets/img/ngbc/Clientes/cliente%20${n}.jpg`);

  constructor() { }

  containers = [
    {
      title: 'Descubre una nueva forma de modelar tus datos',
      subTitle: 'Conoce a Knime',
      text: 'Knime es una plataforma de Data Science y minería de datos que ofrece una interfaz dinamica y visual con la metodología de desarrollo Drag and Drop. Es una herramienta orientada a cualquier perfil, que brinda la facilidad de aprendero sin necesidad de conocimientos técnicos previos.',
      link: {
        text: 'ESCRÍBENOS PARA MAS INFORMACIÓN',
      },
      image: 'assets/img/home-asset23.png'
    },
    {
      title: 'Social Media Analytics',
      subTitle: 'Conoce tu impacto y el de tus competidores',
      text: 'En Dux Analytics hemos desarrollado una herramienta que te permite analizar el comportamiento de tus redes sociales (Facebook e Instagram) y compararte con los competidores de tu elección.',
      link: {
        text: 'ESCRÍBENOS PARA MAS INFORMACIÓN',
      },
      image: 'assets/img/home-asset24.png'
    },
    {
      title: 'Curso de Tableau',
      subTitle: 'Abiertos a la Innovación',
      text: 'Herramienta de Visualizacion y analitica de datos líder en el mercado que brinda un excelente potencial para comprender el comportamiento de los datos de una forma visual. Te invitamos a que te suscribas a nuestro newsletter para próximas fechas.',
      link: {
        text: 'ESCRÍBENOS PARA MAS INFORMACIÓN',
      },
      image: 'assets/img/home-asset25.png'
    }
  ];

  indexContainer: number = 0;

  ngOnInit() {
    // tslint:disable-next-line:only-arrow-functions
    $(window).scroll(function() {
      var fromTop = $(window).scrollTop();
      $("#form01").css('margin', (128 - (fromTop * 0.5 )) + 'px 0px 0px 0px');
      $("#form02").css('margin', (208 + (fromTop * 0.2 )) + 'px 0px 0px 0px');
      $("#form03").css('margin', (442 - (fromTop * 0.1 )) + 'px 0px 0px 0px');
      $("#image01").css('margin', (142 - (fromTop * 0.2 )) + 'px 0px 0px 0px');
      $("#image02").css('margin', (632 - (fromTop * 0.1 )) + 'px 0px 0px 0px');
    });
  }

  nextContainer() {
    this.indexContainer++;
    console.log(this.indexContainer);
  }

  prevContainer() {
    this.indexContainer--;
    console.log(this.indexContainer);
  }

  scrollToBottom() {
    $("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }


}
