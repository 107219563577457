import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { ServicesComponent } from './services/services.component';
import { OpenDataAnalyticsComponent } from './services/open-data-analytics/open-data-analytics.component';
import { BusinessIntelligenceComponent } from './services/business-intelligence/business-intelligence.component';
import { DataCollectorComponent } from './services/data-collector/data-collector.component';
import { ForecastingComponent } from './services/forecasting/forecasting.component';
import { ConsultanciesComponent } from './services/consultancies/consultancies.component';
import { CoursesComponent } from './services/courses/courses.component';
import { SocialMediaComponent } from './services/social-media/social-media.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {LandingSocialMediaComponent} from './landing/social-media/social-media.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NosotrosComponent,
    ServicesComponent,
    OpenDataAnalyticsComponent,
    BusinessIntelligenceComponent,
    DataCollectorComponent,
    ForecastingComponent,
    ConsultanciesComponent,
    CoursesComponent,
    SocialMediaComponent,
    LandingSocialMediaComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule
    ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
