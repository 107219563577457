import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';



declare var $: any;

@Component({
  selector: 'app-landing-social-media-collector',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class LandingSocialMediaComponent implements OnInit {

  thanksText: boolean = false;


  constructor(private title: Title, private meta: Meta, private http: HttpClient) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Social Media');
	  this.meta.updateTag({ name: 'description', content: 'Hemos desarrollado un servicio que te permite sacar el mejor provecho de tus datos generados en las redes sociales de Facebook e Instagram, pero más interesante aun, medirte con tus competidores.' });
  }

  scrollToBottom() {
    //$("html, body").animate({ scrollTop: /*$(document).height()*/ 50 }, 1000);
  }

  sendEmail(form: NgForm) {
    const postVars = {
      email : form.value.email,
      name : form.value.name,
      lastname: form.value.lastname,
      message : "Curso Tableau: " + form.value.message,
      phone: form.value.phone
    };

    this.http.post('https://duxanalytics.com/sendEmail.php', postVars, { headers: {
        'Content-Type': 'application/json'
      } })
      .subscribe(
        response => {
          console.log(response);
          this.thanksText = true;
          form.reset();
        },
        response => console.log(response)
      );
  }

}
