import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-open-data-analytics',
  templateUrl: './open-data-analytics.component.html',
  styleUrls: ['./open-data-analytics.component.css']
})
export class OpenDataAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToBottom() {
    //$("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }


}
