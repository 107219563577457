import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Servicios');
	  this.meta.updateTag({ name: 'description', content: 'Contamos con soluciones integrales y completas, que abarcan toda una serie de pasos para recopilar, enriquecer, gestionar y analizar la información' });
  }

  scrollToServices() {
    /*$([document.body, document.documentElement]).animate({
      scrollTop: $("#services-container").offset().top
    }, 1000);*/
  }
}
