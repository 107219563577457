import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-data-collector',
  templateUrl: './data-collector.component.html',
  styleUrls: ['./data-collector.component.css']
})
export class DataCollectorComponent implements OnInit {

  images = [1,2,3,4,5,6,7,8,9,10].map((n) => `http://localhost:4200/assets/img/ngbc/DataCollector/data collector ${n}.jpg`);

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Data Collector');
	  this.meta.updateTag({ name: 'description', content: 'Recolección masiva de datos sin conexión a internet aplicable a cualquier sector de la industria.' });
  }

  scrollToBottom() {
    //$("html, body").animate({ scrollTop: /*$(document).height()*/ 50 }, 1000);
  }

}
