import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgForm} from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dux-page';

  thanksText: boolean = false;

  constructor(private http: HttpClient) {
  }






  sendEmail(form: NgForm) {
    const postVars = {
      email : form.value.email,
      name : form.value.name,
      lastname: form.value.lastname,
      message : form.value.message,
      phone: form.value.phone
    };

    this.http.post('https://duxanalytics.com/sendEmail.php', postVars, { headers: {
        'Content-Type': 'application/json'
      } })
      .subscribe(
        response => {
          console.log(response);
          this.thanksText = true;
          form.reset();
        },
        response => console.log(response)
      );
  }

}
