import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-business-intelligence',
  templateUrl: './business-intelligence.component.html',
  styleUrls: ['./business-intelligence.component.css']
})
export class BusinessIntelligenceComponent implements OnInit {

  images = [1,2,3,4,5,6,7,8].map((n) => `http://localhost:4200/assets/img/ngbc/Dashboards/Dashboard ${n}.png`);

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
  	this.title.setTitle('Dux Analytics | Business Intelligence');
	  this.meta.updateTag({ name: 'description', content: 'Business Intelligence para analizar sus datos permitiendo responder esas preguntas que impactan su negocio.' });
  }

  scrollToBottom() {
  }


}
