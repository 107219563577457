import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-consultancies',
  templateUrl: './consultancies.component.html',
  styleUrls: ['./consultancies.component.css']
})
export class ConsultanciesComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Asesorias');
	  this.meta.updateTag({ name: 'description', content: 'Con nuestro equipo de consultores podrás asegurar el éxito de cualquier proyecto de Business Intelligence. Tenemos más de 5 años de experiencia implementando soluciones a todo nivel.' });
  }

  scrollToBottom() {
    //$("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }


}
