import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Dux Analytics | Social Media Analytics');
	  this.meta.updateTag({ name: 'description', content: 'Hemos desarrollado un servicio que te permite sacar el mejor provecho de tus datos generados en las redes sociales de Facebook e Instagram, pero más interesante aun, medirte con tus competidores.' });
  }


  scrollToBottom() {
    //$("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }

}
