import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }


  ngOnInit() {
    this.title.setTitle('Dux Analytics | Capacitaciones');
	  this.meta.updateTag({ name: 'description', content: 'A través de nuestros cursos te aseguramos que tu personal estará apto para participar o llevar a cabo proyectos de analítica.' });
  }

  scrollToBottom() {
    //$("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }

}
