import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NosotrosComponent} from './nosotros/nosotros.component';
import {ServicesComponent} from './services/services.component';
import {OpenDataAnalyticsComponent} from './services/open-data-analytics/open-data-analytics.component';
import {BusinessIntelligenceComponent} from './services/business-intelligence/business-intelligence.component';
import {DataCollectorComponent} from './services/data-collector/data-collector.component';
import {ForecastingComponent} from './services/forecasting/forecasting.component';
import {ConsultanciesComponent} from './services/consultancies/consultancies.component';
import {CoursesComponent} from './services/courses/courses.component';
import {SocialMediaComponent} from './services/social-media/social-media.component';
import {LandingSocialMediaComponent} from './landing/social-media/social-media.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: NosotrosComponent
  },
  {
    path: 'cursos-tableau',
    component: LandingSocialMediaComponent
  },
  {
    path: 'services',
    children: [
      {
        path: '',
        component: ServicesComponent,
      },
      {
        path: 'open-data-analytics',
        component: OpenDataAnalyticsComponent
      },
      {
        path: 'business-intelligence',
        component: BusinessIntelligenceComponent
      },
      {
        path: 'data-collector',
        component: DataCollectorComponent
      },
      {
        path: 'forecasting',
        component: ForecastingComponent
      },
      {
        path: 'consultancies',
        component: ConsultanciesComponent
      },
      {
        path: 'courses',
        component: CoursesComponent
      },
      {
        path: 'social-media',
        component: SocialMediaComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
